import React from 'react'
import { Card } from 'antd';
import '../global.scss'

function ProductCard({ image, title, info, price }) {
	return (
		<Card
        className="product_card product_category_card"
      >
        <div className="product_image">
    <img alt="product" src={image} />
  </div>
       <div className='product_card_main'>
         <Card.Meta title={title} description={info} />
		 <p className='product_card_price'>{price}</p>
       </div>
      </Card>
	)
}

export default ProductCard
