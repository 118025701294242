import { Card, Pagination } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import notFound from '../../assets/svg/noData.svg'
import '../../global.scss'
import './searchResult.scss'

function SearchResult() {
	const title = useParams()
	const [data, setData] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [resultCount, setResultCount] = useState(0)
	const [totalCount, setTotalCount] = useState(0)
	const [pagesCount, setPagesCount] = useState(1)
	const [params] = useState({
		limit: 10,
	})

	useEffect(() => {
		const url = new URL(window.location.href)
		const pageParam = url.searchParams.get('page')
		const initialPage = pageParam ? parseInt(pageParam, 10) : 1
		axios
			.get(
				`https://api.protool.uz/v1/products?search=${title.title}&limit=${params.limit}&page=${initialPage}`
			)
			.then(res => {
				setData(res.data.data)
				setResultCount(res.data.resultCount)
				setTotalCount(res.data.totalCount)
				setPagesCount(res.data.pagesCount)
				setCurrentPage(initialPage)
			})
			.catch(err => {
				console.log(err)
			})
	}, [title, currentPage, params.limit])

	const currentPosts = data

	const handlePageChange = page => {
		setCurrentPage(page)
		const url = new URL(window.location.href)
		url.searchParams.set('page', page)
		window.history.pushState({}, '', url)

		window.scrollTo(0, 0)
	}

	return (
		<div className='search_results'>
			<Helmet>
				<title>{`${title.title}`} - Qidiruv natijalari | Protools</title>
				<meta
					name='description'
					content='Mahsulotlarni qidirib toping va tanlang. Protools - Sizning ishonchli sherqingiz.'
				/>
			</Helmet>
			<div className='container'>
				<div className='search_results_wrap'>
					{currentPosts.length > 0 ? (
						currentPosts?.map((item, index) => (
							<Link to={`/product/${item.slug}`} key={index}>
								<Card>
									<img src={item.images[0]} alt={item.title} />
									<h3>{item?.title}</h3>
									<p className='product-description'>{item.description}</p>
									<p className='product-price'>
										{`${item.price.toLocaleString({
											style: 'currency',
											minimumFractionDigits: 0,
											currency: 'UZS',
										})} so'm`}
									</p>
								</Card>
							</Link>
						))
					) : (
						<img
							src={notFound}
							alt='not found'
							className='notFound'
							width={500}
							height={500}
						/>
					)}
				</div>
				{currentPosts.length > 0 && (
					<Pagination
						current={currentPage}
						total={totalCount}
						pageSize={params.limit}
						onChange={handlePageChange}
					/>
				)}
			</div>
		</div>
	)
}

export default SearchResult
