import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/logo.png'
import '../global.scss'

function Footer() {
	return (
		<footer className='footer'>
			<div className='container'>
				<div className='footer_top'>
					<div className='footer_left'>
						<Link to={'/'}>
							<img src={logo} alt='logo' />
						</Link>
						<p className='footer_text'></p>
					</div>
					<div className='footer_right'>
						<div>
							<h3 className='footer_right_caption'>Product</h3>
							<ul className='footer_right_list'>
								<li>
									<Link to={'overview'} className='footer_right_text'>
										Overview
									</Link>
								</li>
								<li>
									<Link to={'/features'} className='footer_right_text'>
										Features
									</Link>
								</li>
								<li>
									<Link to={'/solutions'} className='footer_right_text'>
										Solutions
									</Link>
								</li>
								<li>
									<Link to={'/tutorials'} className='footer_right_text'>
										Tutorials
									</Link>
								</li>
								<li>
									<Link to={'/pricing'} className='footer_right_text'>
										Pricing
									</Link>
								</li>
								<li>
									<Link to={'/releases'} className='footer_right_text'>
										Releases
									</Link>
								</li>
							</ul>
						</div>
						<div>
							<h3 className='footer_right_caption'>Company</h3>
							<ul className='footer_right_list'>
								<li>
									<Link to={'/about'} className='footer_right_text'>
										About us
									</Link>
								</li>
								<li>
									<Link to={'/careers'} className='footer_right_text'>
										Careers
									</Link>
								</li>
								<li>
									<Link to={'/press'} className='footer_right_text'>
										Press
									</Link>
								</li>
								<li>
									<Link to={'/news'} className='footer_right_text'>
										News
									</Link>
								</li>
								<li>
									<Link to={'/mediakit'} className='footer_right_text'>
										Media kit
									</Link>
								</li>
								<li>
									<Link to={'/contact'} className='footer_right_text'>
										Contact
									</Link>
								</li>
							</ul>
						</div>
						<div>
							<h3 className='footer_right_caption'>Resources</h3>
							<ul className='footer_right_list'>
								<li>
									<Link to={'/blog'} className='footer_right_text'>
										Blog
									</Link>
								</li>
								<li>
									<Link to={'/newsletter'} className='footer_right_text'>
										Newsletter
									</Link>
								</li>
								<li>
									<Link to={'/events'} className='footer_right_text'>
										Events
									</Link>
								</li>
								<li>
									<Link to={'/helpcentre'} className='footer_right_text'>
										Help centre
									</Link>
								</li>
								<li>
									<Link to={'/tutorials'} className='footer_right_text'>
										Tutorials
									</Link>
								</li>
								<li>
									<Link to={'/support'} className='footer_right_text'>
										Support
									</Link>
								</li>
							</ul>
						</div>
						<div className='footer_right_mobile'>
							<h3 className='footer_right_caption'>Use cases</h3>
							<ul className='footer_right_list'>
								<li>
									<Link to={'/startups'} className='footer_right_text'>
										Startups
									</Link>
								</li>
								<li>
									<Link to={'/enterprise'} className='footer_right_text'>
										Enterprise
									</Link>
								</li>
								<li>
									<Link to={'/government'} className='footer_right_text'>
										Government
									</Link>
								</li>
								<li>
									<Link to={'/saas'} className='footer_right_text'>
										SaaS
									</Link>
								</li>
								<li>
									<Link to={'/marketplaces'} className='footer_right_text'>
										Marketplaces
									</Link>
								</li>
								<li>
									<Link to={'/ecommerce'} className='footer_right_text'>
										Ecommerce
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className='footer_bottom'>
				<div className='container'>
					<div className='footer_bottom_wrap'>
						<p>© 2023 Protocols. All rights reserved.</p>
						<div className='footer_bottom_socials'>
							<Link to={'https://www.instagram.com/protools.uz/'}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 20 20'
									fill='none'
								>
									<path
										d='M14.4806 0H5.51916C2.4759 0 0 2.47601 0 5.51928V14.4807C0 17.5241 2.4759 20 5.51916 20H14.4806C17.5241 20 20 17.524 20 14.4807V5.51928C20.0001 2.47601 17.5241 0 14.4806 0ZM18.2256 14.4807C18.2256 16.5456 16.5456 18.2255 14.4807 18.2255H5.51916C3.45435 18.2256 1.7745 16.5456 1.7745 14.4807V5.51928C1.7745 3.45447 3.45435 1.7745 5.51916 1.7745H14.4806C16.5455 1.7745 18.2255 3.45447 18.2255 5.51928V14.4807H18.2256Z'
										fill='#98A2B3'
									/>
									<path
										d='M9.9992 4.8468C7.15752 4.8468 4.8457 7.15862 4.8457 10.0003C4.8457 12.8419 7.15752 15.1536 9.9992 15.1536C12.8409 15.1536 15.1527 12.8419 15.1527 10.0003C15.1527 7.15862 12.8409 4.8468 9.9992 4.8468ZM9.9992 13.3789C8.13609 13.3789 6.6202 11.8633 6.6202 10.0002C6.6202 8.13696 8.13597 6.62118 9.9992 6.62118C11.8624 6.62118 13.3782 8.13696 13.3782 10.0002C13.3782 11.8633 11.8623 13.3789 9.9992 13.3789Z'
										fill='#98A2B3'
									/>
									<path
										d='M15.3697 3.34204C15.0278 3.34204 14.6919 3.48045 14.4505 3.72297C14.2078 3.9643 14.0684 4.30027 14.0684 4.64334C14.0684 4.98534 14.208 5.3212 14.4505 5.56371C14.6918 5.80504 15.0278 5.94464 15.3697 5.94464C15.7127 5.94464 16.0475 5.80504 16.29 5.56371C16.5325 5.3212 16.671 4.98522 16.671 4.64334C16.671 4.30027 16.5325 3.9643 16.29 3.72297C16.0487 3.48045 15.7127 3.34204 15.3697 3.34204Z'
										fill='#98A2B3'
									/>
								</svg>
							</Link>
							<Link to={'https://t.me/protools1'}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 23 18'
									fill='none'
								>
									<path
										d='M9.02501 11.7041L8.64455 16.4505C9.18889 16.4505 9.42465 16.2431 9.70736 15.9941L12.2595 13.8308L17.5477 17.2657C18.5175 17.7451 19.2008 17.4926 19.4625 16.4743L22.9336 2.04819L22.9346 2.04734C23.2422 0.775743 22.4161 0.278496 21.4712 0.590444L1.06778 7.51876C-0.324712 7.99816 -0.303629 8.68665 0.831065 8.9986L6.0474 10.4376L18.1639 3.71333C18.7341 3.37843 19.2526 3.56373 18.8261 3.89863L9.02501 11.7041Z'
										fill='#98A2B3'
									/>
								</svg>
							</Link>
							<Link to={'https://m.facebook.com/p/Protoolsuz-100063923449911/'}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
								>
									<g clipPath='url(#clip0_7_1151)'>
										<path
											d='M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z'
											fill='#98A2B3'
										/>
									</g>
									<defs>
										<clipPath id='clip0_7_1151'>
											<rect width='24' height='24' fill='white' />
										</clipPath>
									</defs>
								</svg>
							</Link>
							<Link className='https://www.youtube.com/@protoolsuz'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 21 16'
									fill='none'
								>
									<path
										d='M8.29288 0.523473C4.74959 0.590551 2.66916 0.756012 2.01657 1.02433C1.42529 1.27028 0.930366 1.73089 0.636917 2.29882C0.255871 3.03669 0.0543977 4.65552 0.00621944 7.36102C-0.0288193 9.52096 0.0850566 11.4841 0.325948 12.7273C0.584359 14.0823 1.29827 14.8559 2.52901 15.1198C3.65025 15.3613 6.62416 15.5044 10.5266 15.4999C14.5123 15.4999 17.3504 15.3657 18.5417 15.1198C18.9315 15.0393 19.4834 14.7576 19.7725 14.4982C20.0747 14.2254 20.3813 13.7559 20.5083 13.3713C20.8587 12.3159 21.0733 9.2437 20.9769 6.69471C20.8455 3.23345 20.5696 2.14678 19.6367 1.4268C19.2075 1.09588 18.9096 0.975136 18.2439 0.854394C17.4205 0.711293 15.4408 0.58608 13.3516 0.541361C10.9076 0.492169 10.0317 0.487698 8.29288 0.523473ZM11.1179 6.39062C12.5939 7.25817 13.7983 7.98709 13.7983 8.00945C13.794 8.04523 8.47246 11.2069 8.41552 11.2069C8.41114 11.2069 8.40238 9.76691 8.40238 8.00945C8.40238 6.25199 8.41114 4.81203 8.4199 4.81203C8.42866 4.81203 9.64187 5.52307 11.1179 6.39062Z'
										fill='#98A2B3'
									/>
								</svg>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
