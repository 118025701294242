import React from 'react'
import FamousProduct from '../../components/famousProduct'
import Carousel from './carousel'
import Categories from './categories'
import Intro from './intro'
import News from './news'

function Home() {
	return (
		<div className='home'>
			<Carousel />
			<Intro />
			<Categories />
			<FamousProduct />
			<News />
		</div>
	)
}

export default Home
