import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CategoryCards from '../../components/categoryCards'
import './home.scss'

function Categories() {
	const [data, setData] = useState([])

	useEffect(() => {
		axios
			.get('https://protools.uz/v1/categories/?featured=true')
			.then(res => {
				setData(res.data.data)
			})
			.catch(err => {
				console.log(err)
			})
	}, [])

	return (
		<div className='categories'>
			<div className='container'>
				<h2 className='home_headline'>Mashhur kategoriyalar</h2>
				<div className='categories_cards'>
					{data.length > 0 &&
						data?.map((item, index) => (
							<Link to={`category/${item._id}`} key={index}>
								<CategoryCards image={item.image} text={item.title} />
							</Link>
						))}
				</div>
			</div>
		</div>
	)
}

export default Categories
