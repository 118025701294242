import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MenuContext } from '../Context'
import logo from '../assets/images/logo.png'

function MobileSearch() {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [product, setProduct] = useState([])
	const [loading, setLoading] = useState(true)
	const [key, setkey] = useState('')
	const navigate = useNavigate()
	const ref = useRef(null)
	const { menu, setMenu, setSub } = useContext(MenuContext)

	useEffect(() => {
		if (key.length > 2) {
			axios
				.get(`https://api.protool.uz/v1/products?search=${key}&limit=1000`)
				.then(res => {
					setProduct(res.data.data)
					setLoading(false)
				})
				.catch(err => {
					setLoading(false)
					console.log(err)
				})
		}
	}, [key])

	const onSearchOpen = e => {
		e.preventDefault()
		setIsModalOpen(true)
		setMenu(false)
		setSub(false)
	}

	const onClear = () => {
		ref.current.value = ''
	}

	const onSearch = event => {
		event.preventDefault()
		navigate(`/search/${key}`)
		setIsModalOpen(false)
		setkey('')
	}

	const closeModal = () => {
		setIsModalOpen(false)
		setkey('')
	}

	return (
		<div className='search'>
			<button className='search_btn' onClick={onSearchOpen}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='20'
					height='20'
					viewBox='0 0 20 20'
					fill='none'
				>
					<path
						d='M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z'
						stroke='#667085'
						strokeWidth='1.66667'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			</button>
			<div className={isModalOpen ? 'activeModal' : 'modal'}>
				<div className='modal'>
					<div className='modal-content'>
						<div className='search_wrap'>
							<Link to={'/'}>
								<img src={logo} alt='logo' />
							</Link>
							<button className='close-modal' onClick={closeModal}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='32'
									height='32'
									viewBox='0 0 32 32'
									fill='none'
								>
									<g clipPath='url(#clip0_87_5123)'>
										<path
											d='M8.45703 23.5426L23.542 8.45764'
											stroke='#011E34'
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M23.543 23.5426L8.45802 8.45764'
											stroke='#011E34'
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</g>
									<defs>
										<clipPath id='clip0_87_5123'>
											<rect width='32' height='32' fill='white' />
										</clipPath>
									</defs>
								</svg>
							</button>
						</div>
						<form
							onSubmit={onSearch}
							action={`/search/${key}`}
							onClick={onClear}
						>
							<div className='search_item'>
								<input
									ref={ref}
									onChange={e => setkey(e.target.value)}
									type='text'
									placeholder='search'
									autoComplete='off'
									name='search'
								/>
								<button type='submit'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='20'
										height='20'
										viewBox='0 0 20 20'
										fill='none'
									>
										<path
											d='M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z'
											stroke='#667085'
											strokeWidth='1.66667'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</button>
							</div>
							<div className='search_results'>
								{!loading && product.length > 0 && key.length > 0 ? (
									<div>
										<div className='search_results_wrap'>
											{product.slice(0, 3).map(e => (
												<Link
													to={`product/${e.slug}`}
													key={e?._id}
													className='search_results_card'
													onClick={() => {
														closeModal()
														onClear()
													}}
												>
													<h3>{e?.title}</h3>
													<div className='search_results_card_main'>
														<img src={e.images[0]} alt={e.title} />
													</div>
												</Link>
											))}
										</div>
										<Link
											to={`search/${key}`}
											onClick={() => {
												closeModal()
												onClear()
											}}
										>
											Show all results ({product.length})
										</Link>
									</div>
								) : (
									<div></div>
								)}
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MobileSearch
