import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { MenuProvider, TitleProvider, useMenuProvider } from "./Context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MenuProvider>
      <TitleProvider>
        <App />
      </TitleProvider>
    </MenuProvider>
  </React.StrictMode>
);
