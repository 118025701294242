import { Helmet } from 'react-helmet'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Companies from './layout/companies'
import Footer from './layout/footer'
import Header from './layout/header'
import Home from './pages/home/home'
import Product from './pages/product/product'

import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/thumbs'

import Categories from './pages/categories/categories'
import Category from './pages/categories/category/category'
import Contact from './pages/contact/contact'
import News from './pages/news/news'
import SearchResult from './pages/searchResult/searchResult.jsx'
import ScrollToTop from './scroltop'

const queryClient = new QueryClient()

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<ScrollToTop />
				<Header />
				<main>
					<Helmet>
						<title>Protools.uz</title>
						<meta
							name='description'
							content="Elektr asboblari do'konlari tarmog'i"
						/>
					</Helmet>
					<Routes>
						<Route path='/' element={<Home />} />
						<Route path='/product/:slug' element={<Product />} />
						<Route path='/contact' element={<Contact />} />
						<Route path='/category' element={<Categories />} />
						<Route path='category/:id' element={<Category />} />
						<Route path='/news' element={<News />} />
						<Route path='/search/:title' element={<SearchResult />} />
					</Routes>
				</main>
				<Companies />
				<Footer />
			</BrowserRouter>
		</QueryClientProvider>
	)
}

export default App
