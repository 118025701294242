import React, { useEffect, useState } from "react";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import CategoryCards from "../../components/categoryCards";

import "./categories.scss";

function Paginations() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultCount, setResultCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pagesCount, setPagesCount] = useState(1);
  const [params] = useState({
    limit: 12,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const url = new URL(window.location.href);
    const pageParam = url.searchParams.get("page");
    const initialPage = pageParam ? parseInt(pageParam, 9) : 1;
    axios
      .get(
        `https://api.protool.uz/v1/categories?&limit=${params.limit}&page=${initialPage}`
      )
      .then((res) => {
        setData(res.data.data);
        setResultCount(res.data.resultCount);
        setTotalCount(res.data.totalCount);
        setPagesCount(res.data.pagesCount);
        setCurrentPage(initialPage);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        setLoading(false);
      });
  }, [currentPage, params.limit]);

  const currentPosts = data;

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const url = new URL(window.location.href);
    url.searchParams.set("page", page);
    window.history.pushState({}, "", url);

    window.scrollTo(0, 0);
  };

  return (
    <div>
      <h2 className="title">Kategoriyalar</h2>
      <div className="categoriesPage_wrap">
        {loading ? (
          <div className="loading-spinner"></div>
        ) : (
          currentPosts.length > 0 &&
          currentPosts?.map((item, index) => (
            <Link key={index} to={`/category/${item._id}`}>
              <CategoryCards image={item.image} text={item.title} />
            </Link>
          ))
        )}
      </div>
      <Pagination
        current={currentPage}
        total={totalCount}
        pageSize={params.limit}
        onChange={handlePageChange}
      />
    </div>
  );
}

export default Paginations;
