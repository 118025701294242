import { Helmet } from 'react-helmet'
import {
	Autoplay,
	EffectCoverflow,
	FreeMode,
	Mousewheel,
	Navigation,
	Thumbs,
} from 'swiper/modules'
import './product.scss'

import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import axios from 'axios'
import { useParams } from 'react-router-dom'

function ProductHero() {
	const { slug } = useParams()
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)
	const [thumbsSwiper, setThumbsSwiper] = useState(null)

	useEffect(() => {
		axios
			.get(`https://api.protool.uz/v1/products/slug/${slug}`)
			.then(res => {
				setData(res.data.data)
				setLoading(false)
			})
			.catch(err => {
				console.log(err)
				setLoading(false)
			})
	}, [slug])

	return (
		<div className='productHero'>
			<Helmet>
				<title>{`${data.title}`} | Protools</title>
				<meta name='description' content={`${data?.description}`} />
				<meta property='og:title' content={`${data?.title} | Protools`} />
				<meta property='og:description' content={`${data?.description}`} />
				<meta property='og:type' content='website' />
				<meta
					property='og:url'
					content={`https://www.protools.uz/product/slug/${slug}`}
				/>
				<meta
					property='og:image'
					content={`${data?.images?.length > 0 ? data?.images[0] : ''}`}
				/>
			</Helmet>
			<div className='container'>
				<div className='productHero_main'>
					{loading ? (
						<div className='loading-spinner'></div>
					) : (
						<>
							<div className='productHero_left'>
								<Swiper
									onSwiper={setThumbsSwiper}
									loop={true}
									spaceBetween={10}
									slidesPerView={4}
									freeMode={true}
									direction='vertical'
									watchSlidesProgress={true}
									modules={[FreeMode, Navigation, Thumbs, EffectCoverflow]}
									className='mySwiper'
								>
									{data.images &&
										data?.images.map((item, index) => (
											<SwiperSlide key={index} className='swiper_card'>
												<img src={item} alt={data.title} />
											</SwiperSlide>
										))}
								</Swiper>
								<Swiper
									loop={true}
									slidesPerView={1}
									spaceBetween={10}
									mousewheel={true}
									navigation={true}
									autoplay={{
										delay: 3000,
										disableOnInteraction: false,
									}}
									thumbs={{
										swiper:
											thumbsSwiper && !thumbsSwiper.destroyed
												? thumbsSwiper
												: null,
									}}
									modules={[Autoplay, FreeMode, Navigation, Thumbs, Mousewheel]}
									className='mySwiper2'
								>
									{data.images &&
										data?.images.map((item, index) => (
											<SwiperSlide key={index} className='swiper2_card'>
												<img src={item} alt={data.title} key={index} />
											</SwiperSlide>
										))}
								</Swiper>
							</div>
							<div className='productHero_right'>
								<h2>{data.title} (850 vt)</h2>
								<div className='productHero_text_wrap'>
									<div>
										<span className='productHero_name'>Kuchlanish (V):</span>{' '}
										<span className='productHero_value'>220 Chastota</span>
									</div>
									<div>
										<span className='productHero_name'>
											Gs50 Aylanish tezligi:{' '}
										</span>{' '}
										<span className='productHero_value'>0-1300 ayl/daq</span>
									</div>
									<div>
										<span className='productHero_name'>Quvvat sarfi:</span>{' '}
										<span className='productHero_value'>850 Vt</span>
									</div>
									<div>
										<span className='productHero_name'>Patron hajmi:</span>{' '}
										<span className='productHero_value'>28 mm</span>
									</div>
									<div>
										<span className='productHero_name'>Patron:</span>{' '}
										<span className='productHero_value'>turisds-plus</span>
									</div>
									<div>
										<span className='productHero_name'>Kafolat (oy):</span>{' '}
										<span className='productHero_value'>6</span>
									</div>
									<div>
										<span className='productHero_name'>Og‘irligi (netto):</span>{' '}
										<span className='productHero_value'>5,3 kg</span>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
				<h2 className='productHero_title'>Tavsifi</h2>
				<p className='productHero_description'>{data.description}</p>
			</div>
		</div>
	)
}

export default ProductHero
