import React, { useEffect, useState } from 'react'

import axios from 'axios'
import { Link } from 'react-router-dom'
import CategoryCards from '../../components/categoryCards'
import Paginations from './pagination'

function Categories() {
	const [data, setData] = useState([])

	useEffect(() => {
		axios
			.get('https://api.protool.uz/v1/categories')
			.then(res => {
				setData(res.data.data)
			})
			.catch(error => {
				console.error('Error fetching categories:', error)
			})
	}, [])

	return (
		<div className='categoriesPage'>
			<div className='container'>
				<Paginations />
				<div className='categoriesPage'>
					<h2 className='title'>Mashhur kategoriyalar</h2>
					<div className='categoriesPage_wrap'>
						{data?.map((item, index) => (
							<Link key={index} to={`/category/${item._id}`}>
								<CategoryCards image={item.image} text={item.title} />
							</Link>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Categories
