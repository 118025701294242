import { Drawer } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { MenuContext } from '../Context'
import logo from '../assets/images/logo.png'
import CatalogModal from '../components/CatalogModal'
import MobileSearch from '../components/mobileSearch'
import '../global.scss'

function Header() {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)
	const [product, setProduct] = useState([])
	const [active, setActive] = useState(false)
	const [isActive, setIsActive] = useState(false)
	const [open, setOpen] = useState(false)
	const [showAllSubcategories, setShowAllSubcategories] = useState(false)
	const [count, setCount] = useState(5)
	const [key, setkey] = useState('')
	const navigate = useNavigate()
	const ref = useRef(null)

	const { menu, setMenu, sub, setSub } = useContext(MenuContext)

	useEffect(() => {
		if (key.length > 2) {
			axios
				.get(`https://api.protool.uz/v1/products?search=${key}&limit=1000`)
				.then(res => setProduct(res.data.data))
				.catch(err => console.log(err))
		}

		axios
			.get(`https://api.protool.uz/v1/categories`)
			.then(res => {
				setData(res.data.data)
				setLoading(false)
			})
			.catch(err => {
				Error(err)
				setLoading(false)
			})

		const handleClickOutside = e => {
			if (active && !e.target.closest('.mobile_header')) {
				setActive(false)
			}
		}

		if (active) {
			document.addEventListener('click', handleClickOutside)
		}

		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [active, key])

	const handleClick = () => {
		ref.current.value = ''
	}

	const onSearch = event => {
		event.preventDefault()
		navigate(`/search/${key}`)
		setkey('')
	}

	const handleShowMoreClick = () => {
		setShowAllSubcategories(true)
	}

	return (
		<header className='header'>
			<div className='header_wrap container'>
				<Link to='/' className='header_left'>
					<img src={logo} alt='logo' />
				</Link>
				<div className='header_center_wrap'>
					<div className='header_btn' onClick={() => setOpen(!open)}>
						<div className={open ? `activeHamburger` : `hamburger`} />
						Categories
					</div>
					<CatalogModal onClose={() => setOpen(!open)} showDrawer={open}>
						<div className='dropdown-content container'>
							{loading ? (
								<div className='loading-spinner'></div>
							) : (
								data?.map((category, index) => (
									<div className='category-container' key={index}>
										<NavLink
											onClick={() => setOpen(false)}
											to={`/category/${category._id}`}
											className='category_top'
										>
											{category.title}
											<svg
												width='14'
												height='14'
												viewBox='0 0 14 14'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M10.9641 6.60662L3.92031 1.10505C3.90191 1.09056 3.87979 1.08156 3.8565 1.07907C3.8332 1.07659 3.80968 1.08072 3.78863 1.091C3.76758 1.10128 3.74986 1.11729 3.7375 1.13719C3.72514 1.15709 3.71864 1.18007 3.71875 1.20349V2.4113C3.71875 2.48787 3.75469 2.5613 3.81406 2.60818L9.43906 7.00037L3.81406 11.3926C3.75313 11.4394 3.71875 11.5129 3.71875 11.5894V12.7972C3.71875 12.9019 3.83906 12.9597 3.92031 12.8957L10.9641 7.39412C11.0239 7.34742 11.0724 7.28768 11.1057 7.21945C11.139 7.15122 11.1563 7.07629 11.1563 7.00037C11.1563 6.92444 11.139 6.84951 11.1057 6.78128C11.0724 6.71305 11.0239 6.65332 10.9641 6.60662Z'
													fill='#101828'
												/>
											</svg>
										</NavLink>

										{category.children.length > 0 && (
											<div className='subcategories'>
												{category.children
													.slice(
														0,
														showAllSubcategories ? category.children.length : 5
													)
													.map((subCategory, subIndex) => (
														<NavLink
															onClick={() => setOpen(false)}
															to={`/category/${subCategory._id}`}
															key={subIndex}
															className='category_bottom'
														>
															{subCategory.title}
														</NavLink>
													))}
												{!showAllSubcategories &&
													category.children.length > 5 && (
														<div
															className={`more ${
																showAllSubcategories
																	? 'activeMore'
																	: 'inactiveMore'
															}`}
															onClick={() => {
																handleShowMoreClick()
															}}
														>
															yana {category.children.length - count} ta
														</div>
													)}
											</div>
										)}
									</div>
								))
							)}
						</div>
					</CatalogModal>
					<div className='header_center'>
						<NavLink to={'/spare'}>Zapchastlar</NavLink>
						<NavLink to={'/company'}>Kompaniya</NavLink>
						<NavLink to={'/shops'}>Do’konlar</NavLink>
						<NavLink to={'/news'}>Yangiliklar</NavLink>
						<NavLink to={'/contact'}>Kontaktlar</NavLink>
					</div>
				</div>
				<form
					onSubmit={onSearch}
					action={`/search/${key}`}
					onClick={() => {
						setIsActive(!isActive)
						handleClick()
					}}
					className='header_label'
				>
					<button type='submit' className='header_show_all'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='20'
							height='20'
							viewBox='0 0 20 20'
							fill='none'
						>
							<path
								d='M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z'
								stroke='#667085'
								strokeWidth='1.66667'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
					</button>
					<div>
						<input
							ref={ref}
							onChange={e => setkey(e.target.value)}
							placeholder='qidirish'
							autoComplete='off'
							className='header_search'
						/>
					</div>
					<>
						{key && (
							<div className='search__bar'>
								{product.length > 0 && key.length > 0 ? (
									<div>
										{product?.slice(0, 3).map(
											(e, i) =>
												key && (
													<div className='search__bar_wrap'>
														<Link
															to={`product/${e.slug}`}
															key={i}
															className='search__bar_card'
															onClick={() => setkey(false)}
														>
															<img src={e.images[0]} alt={e.title} />
															<div className='search__bar_card_texts'>
																<h3>{e.title}</h3>
																<p>{`${e.price.toLocaleString({
																	style: 'currency',
																	minimumFractionDigits: 0,
																	currency: 'UZS',
																})} so'm`}</p>
															</div>
														</Link>
													</div>
												)
										)}
										<Link
											to={`search/${key}`}
											className='search__bar_all_views'
											onClick={() => {
												setkey(false)
												handleClick()
											}}
										>
											Barcha natijalarni ko`rish {product.length}
										</Link>
									</div>
								) : (
									<div></div>
								)}
							</div>
						)}
					</>
				</form>
				<div className='mobile_header'>
					<MobileSearch />
					<div
						className='mobile_header_btn'
						onClick={() => {
							setMenu(!menu) ; setSub(false)
						}}
					>
						<div className={menu ? `activeHamburger` : `hamburger`} />
					</div>
					<Drawer
						closable={false}
						autoFocus
						placement='top'
						onClose={() => setMenu(!menu)}
						open={menu}
						className='mobile_drawer'
					>
						<ul className={'mobile_header_list'}>
							<li className='mobile_header_list_first'>
								<span onClick={() => setSub(!sub)}>
									Kategoriyalar
									<svg
										width='14'
										height='14'
										viewBox='0 0 14 14'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M10.9641 6.60662L3.92031 1.10505C3.90191 1.09056 3.87979 1.08156 3.8565 1.07907C3.8332 1.07659 3.80968 1.08072 3.78863 1.091C3.76758 1.10128 3.74986 1.11729 3.7375 1.13719C3.72514 1.15709 3.71864 1.18007 3.71875 1.20349V2.4113C3.71875 2.48787 3.75469 2.5613 3.81406 2.60818L9.43906 7.00037L3.81406 11.3926C3.75313 11.4394 3.71875 11.5129 3.71875 11.5894V12.7972C3.71875 12.9019 3.83906 12.9597 3.92031 12.8957L10.9641 7.39412C11.0239 7.34742 11.0724 7.28768 11.1057 7.21945C11.139 7.15122 11.1563 7.07629 11.1563 7.00037C11.1563 6.92444 11.139 6.84951 11.1057 6.78128C11.0724 6.71305 11.0239 6.65332 10.9641 6.60662Z'
											fill='#101828'
										/>
									</svg>
								</span>
							</li>
							<Drawer
								closable={false}
								autoFocus
								placement='top'
								onClose={() => setSub(!setSub)}
								open={sub}
								className='my_drawer'
							>
								<div className='subDrawer_wrap'>
									<h3 onClick={() => setSub(false)}>
										<svg
											width='11'
											height='19'
											viewBox='0 0 11 19'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M0.00878906 9.40527C0.0143229 9.21159 0.0530599 9.0345 0.125 8.87402C0.19694 8.71354 0.307617 8.55859 0.457031 8.40918L8.77441 0.357422C9.01237 0.119466 9.30566 0.000488281 9.6543 0.000488281C9.88672 0.000488281 10.097 0.0558268 10.2852 0.166504C10.4788 0.277181 10.631 0.426595 10.7417 0.614746C10.8579 0.802897 10.916 1.01318 10.916 1.24561C10.916 1.5887 10.786 1.8903 10.5259 2.15039L3.00537 9.39697L10.5259 16.6519C10.786 16.9175 10.916 17.2191 10.916 17.5566C10.916 17.7946 10.8579 18.0077 10.7417 18.1958C10.631 18.384 10.4788 18.5334 10.2852 18.644C10.097 18.7603 9.88672 18.8184 9.6543 18.8184C9.30566 18.8184 9.01237 18.6966 8.77441 18.4531L0.457031 10.4014C0.302083 10.252 0.188639 10.097 0.116699 9.93652C0.0447591 9.77051 0.00878906 9.59342 0.00878906 9.40527Z'
												fill='#667085'
											/>
										</svg>
										Kategoriyalar
									</h3>
									<div className='category-wrap'>
										{data?.map((category, index) => (
											<div className='category-container' key={index}>
												<NavLink
													onClick={() => setSub(false)}
													to={`/category/${category._id}`}
													className='category_top'
												>
													{category.title}
													<svg
														width='14'
														height='14'
														viewBox='0 0 14 14'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															d='M10.9641 6.60662L3.92031 1.10505C3.90191 1.09056 3.87979 1.08156 3.8565 1.07907C3.8332 1.07659 3.80968 1.08072 3.78863 1.091C3.76758 1.10128 3.74986 1.11729 3.7375 1.13719C3.72514 1.15709 3.71864 1.18007 3.71875 1.20349V2.4113C3.71875 2.48787 3.75469 2.5613 3.81406 2.60818L9.43906 7.00037L3.81406 11.3926C3.75313 11.4394 3.71875 11.5129 3.71875 11.5894V12.7972C3.71875 12.9019 3.83906 12.9597 3.92031 12.8957L10.9641 7.39412C11.0239 7.34742 11.0724 7.28768 11.1057 7.21945C11.139 7.15122 11.1563 7.07629 11.1563 7.00037C11.1563 6.92444 11.139 6.84951 11.1057 6.78128C11.0724 6.71305 11.0239 6.65332 10.9641 6.60662Z'
															fill='#101828'
														/>
													</svg>
												</NavLink>

												{category.children.length > 0 && (
													<div className='subcategories'>
														{category.children
															.slice(
																0,
																showAllSubcategories
																	? category.children.length
																	: 5
															)
															.map((subCategory, subIndex) => (
																<NavLink
																	onClick={() => setSub(false)}
																	to={`/category/${subCategory._id}`}
																	key={subIndex}
																	className='category_bottom'
																>
																	{subCategory.title}
																</NavLink>
															))}
														{!showAllSubcategories &&
															category.children.length > 5 && (
																<div
																	className={`more ${
																		showAllSubcategories
																			? 'activeMore'
																			: 'inactiveMore'
																	}`}
																	onClick={() => {
																		handleShowMoreClick()
																	}}
																>
																	yana {category.children.length - count} ta
																</div>
															)}
													</div>
												)}
											</div>
										))}
									</div>
								</div>
							</Drawer>
							<li className='mobile_header_list_item'>
								<NavLink to={'/spare'} onClick={() => setMenu(!menu)}>
									Zapchastlar
								</NavLink>
							</li>
							<li className='mobile_header_list_item'>
								<NavLink to={'/company'} onClick={() => setMenu(!menu)}>
									Kompaniya
								</NavLink>
							</li>
							<li className='mobile_header_list_item'>
								<NavLink to={'/shop'} onClick={() => setMenu(!menu)}>
									Do’konlar
								</NavLink>
							</li>
							<li className='mobile_header_list_item'>
								<NavLink to={'/news'} onClick={() => setMenu(!menu)}>
									Yangiliklar
								</NavLink>
							</li>
							<li className='mobile_header_list_item'>
								<NavLink to={'/contact'} onClick={() => setMenu(!menu)}>
									Kontaktlar
								</NavLink>
							</li>
						</ul>
					</Drawer>
				</div>
			</div>
		</header>
	)
}

export default Header
