import { Modal } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import YouTube from 'react-youtube'
import LinearCard from '../../components/linearCard'
import '../../global.scss'
import './home.scss'

function News() {
	const [data, setData] = useState([])
	const [selectedCard, setSelectedCard] = useState(null)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [player, setPlayer] = useState(null)
	const [loading, setLoading] = useState(true)

	const formatDate = dateString => {
		const options = {
			hour: 'numeric',
			minute: 'numeric',
			day: 'numeric',
			month: 'numeric',
			year: 'numeric',
		}
		const formattedDate = new Date(dateString).toLocaleString('en-US', options)
		return formattedDate
	}

	useEffect(() => {
		axios
			.get('https://api.protool.uz/v1/articles')
			.then(res => {
				setData(res.data.data)
				setLoading(false)
			})
			.catch(err => {
				console.error(err)
				setLoading(false)
			})
	}, [])

	const firstThreeItems = data.slice(0, 3)

	const handleCardClick = cardData => {
		setSelectedCard(cardData)
		setIsModalOpen(true)
	}

	const onReady = event => {
		setPlayer(event.target)
		setIsModalOpen(true)
	}

	const handleModalClose = () => {
		setIsModalOpen(false)
		if (player) {
			player.stopVideo()
		}
	}

	const opts = {
		playerVars: {
			autoplay: 1,
		},
	}

	return (
		<div className='news'>
			<div className='container'>
				<h2 className='home_headline'>Yangiliklar</h2>

				{loading && <div className='loading-spinner'></div>}

				<div className='news_main'>
					<Link to={'/news'} className='news__one'>
						<img
							className='news__img'
							src={firstThreeItems[0]?.cardImage}
							alt={firstThreeItems[0]?.title}
						/>
						<div className='news__box'>
							<p className='news__detel'>
								{formatDate(firstThreeItems[0]?.createdAt)}
							</p>
							<h2 className='news__heading'>
								{firstThreeItems[0]?.title}{' '}
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M7 17L17 7M17 7H7M17 7V17'
										stroke='#101828'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</h2>
							<p className='news__text'>{firstThreeItems[0]?.body}</p>
						</div>
					</Link>
					<div className='news_wrap'>
						{firstThreeItems.length > 0 &&
							firstThreeItems
								.slice(0, 2)
								.map((item, index) => (
									<LinearCard
										image={item.cardImage}
										date={formatDate(item.createdAt)}
										headline={item.author}
										title={item.title}
										description={item.body}
										text={item.body}
										videoLink={item.excerpt}
										videoLinkTitle={item.slug}
										key={index}
										onCardClick={() => handleCardClick(item)}
									/>
								))}
					</div>
				</div>
			</div>

			<Modal
				visible={isModalOpen}
				onCancel={handleModalClose}
				footer={null}
				afterClose={() => {
					setIsModalOpen(false)
				}}
				className='Modal_ant'
			>
				{selectedCard && (
					<div className='modal'>
						<YouTube
							videoId={selectedCard.excerpt}
							opts={opts}
							className='youtube'
							onReady={onReady}
						/>
					</div>
				)}
			</Modal>
		</div>
	)
}

export default News
