import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import axios from 'axios'
import { useParams } from 'react-router-dom'

import './home.scss'
import '../../global.scss'
import ProductCard from '../../components/productCard';

function Products({ title }) {
	const { slug } = useParams()
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		axios
			.get(`https://api.protool.uz/v1/products/slug/${slug}`)
			.then(res => {
				setData(res.data.data)
				setLoading(false)
			})
			.catch(err => {
				console.log(err)
				setLoading(false)
			})
	}, [slug])

	return (
		<div className='products'>
			<div className='container'>
				<h2 className='home_headline'>{title}</h2>
				{loading ? (
					<div className='loading-spinner'></div>
				) : (
					<Swiper
						spaceBetween={20}
						slidesPerView={4}
						navigation={true}
						breakpoints={{
							100: {
								slidesPerView: 1.5,
							},
							1024: {
								slidesPerView: 4,
							},
						}}
						modules={[Navigation]}
						className='mySwiper'
					>
						{data.recommendProducts?.map((item, index) => (
							<SwiperSlide key={index}>
								<Link to={`/product/${item.slug}`} key={index}>
									<ProductCard image={item.images[0]} title={item.title} info={item.description} price={`${item.price.toLocaleString({
                            style: 'currency',
                            minimumFractionDigits: 0,
                            currency: 'UZS',
                          })} so'm`}/>
								</Link>
							</SwiperSlide>
						))}
					</Swiper>
				)}
			</div>
		</div>
	)
}

export default Products
