import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, EffectFade, Autoplay } from 'swiper/modules'

import 'swiper/css'
import './home.scss'
import axios  from 'axios'

const Carousel = () => {
	const [data, setData] = useState([])

	useEffect(() => {
		axios.get('https://api.protool.uz/v1/banners')
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
	}, [])

	return (
		<div className='slider  container'>
			<Swiper
				style={{
					'--swiper-navigation-color': '#fff',
					'--swiper-pagination-color': '#fff',
				}}
				autoplay={{
					delay: 3000,
					disableOnInteraction: false,
				}}
				speed={6000}
				effect={'fade'}
				pagination={{
					clickable: true,
				}}
				navigation={true}
				modules={[Pagination, EffectFade, Autoplay]}
				className='mySwiper container'
			>
				{
			    data.length > 0 &&
				data?.map((item, index) => (
					<SwiperSlide
						key={index}
						style={{
							backgroundImage: `url(${item.imageUrl})`,
						}}
					></SwiperSlide>
				))}
			</Swiper>
		</div>
	)
}

export default Carousel
