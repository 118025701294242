import { Pagination } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import notFound from '../../../assets/svg/noData.svg'
import ProductCard from '../../../components/productCard'
import '../../../global.scss'
import './category.scss'

function Category() {
	const { id } = useParams()
	const initialPage =
		parseInt(new URLSearchParams(window.location.search).get('page')) || 1
	const [currentPage, setCurrentPage] = useState(initialPage)
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)
	const [postsPerPage] = useState(10)
	const [title, setTitle] = useState([])
	const [params, setParams] = useState({
		resultCount: 1,
		totalCount: 1,
		currentPage: 1,
		limit: 10,
		pagesCount: 1,
	})

	useEffect(() => {
		axios
			.get(`https://api.protool.uz/v1/categories`)
			.then(res => {
				setTitle(res?.data?.data.filter(e => e._id === id))
			})
			.catch(err => {})
		const fetchData = async () => {
			try {
				const res = await axios.get(
					`https://api.protool.uz/v1/products/?category.oid=${id}&page=${currentPage}&limit=${params.limit}`
				)

				setData(res.data)
				setParams({
					resultCount: res.data.resultCount,
					totalCount: res.data.totalCount,
					currentPage: currentPage,
					limit: params.limit,
					pagesCount: Math.ceil(res.data.totalCount / params.limit),
				})
				setLoading(false)
			} catch (error) {
				console.error(error)
				setLoading(false)
			}
		}

		fetchData()
	}, [id, currentPage, params.limit])

	const handlePageChange = page => {
		setCurrentPage(page)

		const url = new URL(window.location.href)
		url.searchParams.set('page', page)
		window.history.pushState({}, '', url)

		window.scrollTo(0, 0)
	}

	return (
		<div className='categoryFilter'>
			<Helmet>
				<title>{`${title[0]?.title} | Protools`}</title>
				<meta property='og:title' content={`${title[0]?.title} | Protools`} />
				<meta property='og:description' content={`${title[0]?.description}`} />
				<meta property='og:type' content='website' />
				<meta property='og:url' content={`https://www.protools.uz/category`} />
				<meta property='og:image' content={title.image && title.image[0]} />
			</Helmet>
			<div className='container'>
				<h2 className='categoryFilter_title'>{`${title[0]?.title} (${data.totalCount})`}</h2>
				<div className='categoryFilter_center'>
					{/* <Filter /> */}
					<div className='categoryFilter_right'>
						<div className='categoryFilter_right_wrap'>
							{loading ? (
								<div className='loading-spinner'></div>
							) : data.data.length > 0 ? (
								data.data.map(item => (
									<Link to={`/product/${item.slug}`} key={item._id}>
										<ProductCard
											image={item.images[0]}
											title={item.title}
											info={item.description}
											price={`${item.price.toLocaleString({
												style: 'currency',
												minimumFractionDigits: 0,
												currency: 'UZS',
											})} so'm`}
										/>
									</Link>
								))
							) : (
								<img
									src={notFound}
									alt='not found'
									className='categoryFilter_right_not_product'
									width={500}
									height={500}
								/>
							)}
						</div>
					</div>
				</div>
				{params.resultCount > 0 && (
					<Pagination
						current={currentPage}
						total={params.totalCount}
						pageSize={postsPerPage}
						onChange={handlePageChange}
					/>
				)}
			</div>
		</div>
	)
}

export default Category
