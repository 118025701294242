import { Modal, Pagination } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import YouTube from 'react-youtube'
import notData from '../../assets/svg/noData.svg'
import '../../global.scss'
import './newsPage.scss'

import LinearCard from '../../components/linearCard'

function News() {
	const [data, setData] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [postsPerPage] = useState(10)
	const [selectedCard, setSelectedCard] = useState(null)
	const [selectedCardIndex, setSelectedCardIndex] = useState(null)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isVideoPlaying, setIsVideoPlaying] = useState(false)
	const [resultCount, setResultCount] = useState(0)
	const [totalCount, setTotalCount] = useState(0)
	const [pagesCount, setPagesCount] = useState(1)
	const [player, setPlayer] = useState(null)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const fetchData = async () => {
			try {
				const url = new URL(window.location.href)
				const pageParam = url.searchParams.get('page')
				const initialPage = pageParam ? parseInt(pageParam, 10) : 1

				const response = await axios.get(
					`https://api.protool.uz/v1/articles?limit=${postsPerPage}&page=${initialPage}`
				)
				setData(response.data.data)
				setResultCount(response.data.resultCount)
				setTotalCount(response.data.totalCount)
				setPagesCount(response.data.pagesCount)
				setCurrentPage(initialPage)
				setLoading(false)
			} catch (error) {
				console.error('Error fetching data:', error)
				setLoading(false)
			}
		}

		fetchData()
	}, [currentPage, postsPerPage])

	const currentPosts = data

	const handleChangePage = page => {
		setCurrentPage(page)
		const url = new URL(window.location.href)
		url.searchParams.set('page', page)
		window.history.pushState({}, '', url)

		window.scrollTo(0, 0)
	}

	const handleCardClick = (cardData, index) => {
		setSelectedCard(cardData)
		setSelectedCardIndex(index)
		setIsModalOpen(true)
		setIsVideoPlaying(false)
	}

	const onReady = event => {
		setPlayer(event.target)
	}

	useEffect(() => {
		if (isModalOpen && isVideoPlaying && player) {
			player.playVideo()
		}
	}, [isModalOpen, isVideoPlaying, player])

	const handleModalClose = () => {
		setIsModalOpen(false)
		setIsVideoPlaying(false)
	}

	const handlePlayButtonClick = () => {
		setIsVideoPlaying(true)
	}

	const opts = {
		playerVars: {
			autoplay: 1,
		},
	}

	const formatDate = dateString => {
		const options = {
			hour: '2-digit',
			minute: '2-digit',
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		}
		const formattedDate = new Date(dateString).toLocaleString('en-US', options)
		const [time, date] = formattedDate.split(', ')
		return `${time} / ${date}`
	}

	return (
		<div className='newsPage'>
			<Helmet>
				<title>Yangiliklar | Protools</title>
				<meta
					name='description'
					content={`Elektr asboblari do'konlari tarmog'idagi eng so'nggi yangiliklar. Qiziqarli o'zgarishlar va chegirma tadbirlari haqida ma'lumotlar.`}
				/>
			</Helmet>
			<div className='container'>
				<h2 className='title'>Yangiliklar</h2>
				<div className='newsPage_wrap'>
					{loading ? (
						<div className='loading_spinner'></div>
					) : resultCount > 0 ? (
						currentPosts.map((item, index) => (
							<LinearCard
								key={index}
								image={item.cardImage}
								date={formatDate(item.createdAt)}
								headline={item.author}
								title={item.title}
								description={item.body}
								text={item.body}
								videoLink={item.excerpt}
								videoLinkTitle={item.slug}
								onCardClick={() => handleCardClick(item, index)}
							/>
						))
					) : (
						<img src={notData} alt='not data' className='not_data' />
					)}
				</div>
				{resultCount > 0 && (
					<Pagination
						current={currentPage}
						total={totalCount}
						pageSize={postsPerPage}
						onChange={handleChangePage}
					/>
				)}
				<Modal
					visible={isModalOpen}
					onCancel={handleModalClose}
					footer={null}
					className='Modal_ant'
					afterClose={() => {
						if (player) {
							player.pauseVideo()
						}
					}}
				>
					{selectedCard &&
					selectedCardIndex !== null &&
					selectedCardIndex !== undefined ? (
						<div className='modal'>
							{!isVideoPlaying && (
								<YouTube
									videoId={selectedCard.excerpt}
									opts={opts}
									className='youtube'
									onReady={onReady}
								/>
							)}
						</div>
					) : null}
				</Modal>
			</div>
		</div>
	)
}

export default News
