import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "../global.scss";

import { Autoplay } from "swiper/modules";
import  axios  from 'axios';

function Companies() {
  const [data, setData] = useState([])

  useEffect(() => {
    axios.get("https://api.protool.uz/v1/brands").then((res) => {
      setData(res.data.data)
    }).catch((err) => {
      Error(err)
    })
  },[])

  return (
    <div className="companies">
      <div className="container">
        <h3>Join 4,000+ companies already growing</h3>
        <Swiper
          breakpoints={{
            100: {
              slidesPerView: 2,
            },
            500: {
              slidesPerView: 5,
            },
          }}
          spaceBetween={30}
          autoplay={{ delay: 5000 }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {data?.map((item, index) => (
            <SwiperSlide className="companies_box" key={index}>
            <img src={item.image} alt={item.name} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default Companies;
